import styled from 'styled-components';
import packageVersion from '../../../package.json';
import BG1440 from '../../assets/bg-1440.png';
import MaxsipLogo from '../../components/Header/assets/dark-nucleus-by-maxsip.svg';
import DesktopXL from './assets/desktop-xl-maxsip.png';
import Logo from './assets/logo.png';
import Desktop from './assets/medium-bg-maxsip.png';
import Mobile from './assets/mobile-bg-maxsip.png';
import Tablet from './assets/tablet-bg-maxsip.png';

const HomeBackGround = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  width: 100%;
  height: 100vh;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: top right;
  background-color: #0092ff;
  /* Default background image (Desktop) */
  background-image: url(${Desktop});

  /* Tablet background image */
  @media (max-width: 1024px) {
    background-image: url(${Tablet});
  }

  /* Mobile background image */
  @media (max-width: 768px) {
    background-position: top right;
    background-size: auto 75%;
    background-image: url(${Mobile});
  }
  @media (min-width: 1025px) {
    background-image: url(${DesktopXL});
  }
  @media (min-width: 1440px) {
    background-image: url(${BG1440});
  }
`;
const LogoImage = styled.img`
  width: 300px;
  height: auto;
  object-fit: contain;
  @media (max-width: 1024px) {
    width: 200px; /* Width for tablet */
  }

  @media (max-width: 768px) {
    width: 100px; /* Width for mobile */
  }
`;

const MainText = styled.p`
  font-size: 48px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 32px; /* Font size for tablet */
  }

  @media (max-width: 768px) {
    font-size: 20px; /* Font size for mobile */
  }
`;

const SubText = styled.p`
  font-size: 24px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  margin: 5px 0;

  @media (max-width: 1024px) {
    font-size: 24px; /* Font size for tablet */
  }

  @media (max-width: 768px) {
    font-size: 16px; /* Font size for mobile */
    width: 56%;
  }
`;

const HomeContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
`;

const ContentInfo = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BrandingFooter = styled.div`
  width: 208px;
  padding: 30px;
`;
const BrandingImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;
const Home = () => {
  return (
    <HomeBackGround>
      <HomeContainer>
        <ContentInfo>
          <LogoImage src={Logo} alt="Logo" />
          <MainText>Welcome to Maxsip Nucleus!</MainText>
          <SubText>To begin, please click on the link you received via text message.</SubText>
        </ContentInfo>
        <Footer>
          <BrandingFooter>
            <BrandingImage src={MaxsipLogo} />
            <span
              style={{
                fontSize: '10px',
              }}
            >
              V: {packageVersion.version}
            </span>
          </BrandingFooter>
        </Footer>
      </HomeContainer>
    </HomeBackGround>
  );
};

export default Home;
